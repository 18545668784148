<template>
  <div>
    <HomePageTop />
    <div class="forgetPsd">
      <div class="forTitle">
        <div class="fg">{{ $fanyi("恢复或更改密码") }}</div>
      </div>
      <div class="checkMethods" v-if="step == 2">
        <div class="checkMethodsCon">
          <h1>{{ $fanyi("请选择认证方式") }}：</h1>
          <h2>
            {{
              $fanyi(
                "为了保护您的账户，Rakumart希望验证您是试图恢复或更改密码的人"
              )
            }}
          </h2>
          <div
            class="checkMethodsOpt"
            @click.prevent="
              approveType = 'email';
              next();
            "
          >
            <img
              style="
                width: 22px;
                height: 19px;
                margin-right: 4px;
                margin-left: -3px;
              "
              :src="require('@/assets/changepassworld/mail@2x.png')"
              alt=""
            />
            <span>{{ $fanyi("电子邮箱") }}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
          <div
            class="checkMethodsOpt"
            @click.prevent="
              approveType = 'mobile';
              next();
            "
          >
            <img src="../../assets/icon/shouJi.png" alt="" />
            <span>{{ $fanyi("手机号码") }}</span>
            <i class="el-icon-arrow-right"></i>
          </div>
        </div>
      </div>
      <div class="changePasswordBox" v-if="step >= 3">
        <div class="identityVerification" v-if="step == 3">
          <div class="name">
            {{
              approveType == "mobile"
                ? $fanyi("你的Rakumart账户的手机号码")
                : $fanyi("电子邮件链接到您的帐户")
            }}
          </div>
          <!-- 新的输入框 -->
          <div class="selinputBox">
            <el-form ref="form" label-position="left">
              <el-form-item :label="$fanyi('你的Rakumart账户的手机号码')">
                <div class="countryBox" v-if="approveType == 'mobile'">
                  {{ country }}
                </div>
                <div class="inputSend" :class="{ succ: onumber }">
                  <input
                    type="text"
                    v-model="onumber"
                    :placeholder="
                      approveType == 'mobile'
                        ? $fanyi('请输入手机号')
                        : $fanyi('请输入邮箱')
                    "
                  />
                  <div class="gou">✔</div>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('验证码')">
                <div class="inputSend" :class="{ succ: yanZhengMaZhengQue }">
                  <input
                    type="text"
                    v-model="regCode"
                    :placeholder="$fanyi('输入验证码')"
                    @blur="checkVerificationCode()"
                  />
                  <div class="gou">✔</div>
                </div>
                <button
                  class="getNum"
                  v-if="showBtn"
                  @click.prevent="sendVerificationCode"
                >
                  {{ $fanyi("获得代码") }}
                </button>
                <button v-else class="sixSec getNum">{{ Countdown }} S</button>
              </el-form-item>
              <el-form-item>
                <button class="nextBtn" @click.prevent="next()">
                  {{ $fanyi("下一步") }}
                </button>
              </el-form-item>
            </el-form>

            <div class="inputSend"></div>
          </div>
        </div>
        <div class="identityVerification" v-if="step == 4">
          <div class="name">
            {{ $fanyi("新密码") }}
          </div>
          <!-- 新的输入框 -->
          <div class="selinputBox">
            <el-form
              ref="formRef"
              :rules="formRules"
              :model="newPassword"
              label-position="left"
            >
              <el-form-item
                :label="$fanyi('新密码') + '：'"
                prop="one"
                class="regerror"
              >
                <div class="inputSend">
                  <el-input v-model="newPassword.one" show-password></el-input>
                </div>
              </el-form-item>
              <el-form-item :label="$fanyi('再次确认密码') + '：'" prop="two">
                <div class="inputSend">
                  <el-input v-model="newPassword.two" show-password></el-input>
                </div>
              </el-form-item>
              <el-form-item>
                <button class="nextBtn" @click.prevent="next()">
                  {{ $fanyi("下一步") }}
                </button>
              </el-form-item>
            </el-form>
          </div>
        </div>

        <div class="ChangeSuccess" v-if="step == 5">
          <div class="ChangeSuccessOpt">
            <p class="successImg">
              <img :src="require('@/assets/newImg/omplete.png')" alt="" />
            </p>
            <p class="messageBox">
              <span>{{ $fanyi("密码修改成功") }}</span>
            </p>
            <p class="messaeSpan">
              <span>{{ $fanyi("您已经有了新密码，请重新登录。") }}</span>
            </p>
            <button
              class="nextBtn"
              @click.prevent="$fun.routerToPage('/login')"
            >
              {{ $fanyi("登录") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue";
export default {
  data() {
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$fanyi("请再次输入密码")));
      } else if (value !== this.newPassword.one) {
        callback(new Error(this.$fanyi("两次输入密码不一致!")));
      } else {
        callback();
      }
    };
    return {
      step: 2,
      login_name: "", //用户名
      approveType: "mobile", //用户验证身份的方式
      showBtn: true, //发送验证码按钮控制
      Countdown: 60, //秒数
      regCode: "", //验证码
      regNext: false,
      yanZhengMaZhengQue: false,
      country: "+34", //手机号区号
      onumber: "", //绑定的邮箱或手机号
      tiShi: "",
      numberTishiTwo: "",
      numberTishi: "",
      newPassword: {
        one: "",
        two: "",
      },
      formRules: {
        one: [
          {
            required: true,
            pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,16}$/,
            message: this.$fanyi("请输入6-16位包含大小写字母和数字的字符串"),
          },
        ],
        two: [
          {
            required: true,
            validator: validatePass2,
          },
        ],
      },
    };
  },
  components: { HomePageTop },
  computed: {},
  created() {},
  methods: {
    // 设置新密码
    forgetPasswordReset() {
      let datas = {
        password: this.newPassword.one,
        login_name:
          this.approveType == "mobile" ? this.onumber.trim() : this.onumber,
        verification: {
          number: this.onumber,
          code: this.regCode,
        },
      };
      this.$api.forgetPasswordReset(datas).then((res) => {
        if (res.success == true) {
          this.step++;
        } else {
          this.numberTishi = res.msg;
          this.regNext = false;
        }
      });
    },
    // 验证验证码
    checkVerificationCode(fn) {
      this.$api
        .checkVerificationCode({
          occasion: "forget",
          number: this.onumber,
          code: this.regCode,
        })
        .then((res) => {
          if (res.success == true) {
            this.yanZhengMaZhengQue = true;
            if (fn) {
              fn();
            }
          } else {
            this.yanZhengMaZhengQue = false;
            this.$message.warning(this.$fanyi("验证码错误"));
          }
        });
    },
    // 发送验证码
    sendVerificationCode() {
      if (!this.onumber) {
        return this.$message(this.$fanyi("必填参数缺失"));
      }
      var emailRegExp = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (this.approveType == "email" && !emailRegExp.test(this.onumber)) {
        return this.$message(this.$fanyi("请输入正确的邮箱地址"));
      }

      let datas = {
        occasion: "forget",
        tool: this.approveType,
        number: this.onumber,
      };
      if (this.approveType == "mobile") {
        datas.area_code = this.country;
      }
      this.showBtn = false;
      this.Countdown = 60;
      this.$api.sendVerificationCode(datas).then((res) => {
        this.showBtn = true;
        if (res.code != 0) return this.$message.error(this.$fanyi(res.msg));
        this.showBtn = false;
        this.numberTishi = "";
        var timer = setInterval(() => {
          this.Countdown--;
          if (this.Countdown <= 0) {
            this.showBtn = true;
            this.Countdown = 60;
            clearInterval(timer);
          }
        }, 1000);
      });
    },

    next() {
      //console.log(this.step);
      switch (this.step) {
        case 2:
          this.step++;
          break;
        case 3:
          if (!this.onumber || !this.regCode) {
            return this.$message(this.$fanyi("必填参数缺失"));
          }
          var emailRegExp =
            /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
          if (this.approveType == "email" && !emailRegExp.test(this.onumber)) {
            return this.$message(this.$fanyi("请输入正确的邮箱地址"));
          }
          this.checkVerificationCode(() => {
            this.step++;
          });
          break;
        case 4:
          this.$refs.formRef.validate((valid) => {
            if (valid) {
              this.forgetPasswordReset();
            }
          });
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "../../css/mixin.scss";
.forgetPsd {
  padding-top: 8px;
  background: #fafafa;
  > div {
    width: $pageWidth;
    margin: 0 auto;
    background: #ffffff;
    border: 1px solid #e1e1e1;
  }
  .forTitle {
    border-bottom: 1px solid #d7d7d7;
    padding: 20px 0;
    padding-left: 87px;
    height: 65px;
    .fg {
      height: 23px;
      border-left: 1px solid #1e2997;
      padding-left: 8px;
      font-size: 16px;
      font-weight: 500;
    }
  }

  // 输入账号
  .inputUserNameBox {
    width: $pageWidth;
    margin: 0 auto;
    min-height: 442px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    .inputUserNameBoxCon {
      padding-top: 80px;
      width: 355px;
      margin-left: 523px;
      .title {
        margin-bottom: 40px;
        font-size: 18px;
        color: #000000;
        line-height: 24px;
      }
      .inputBox {
        display: flex;
        align-items: center;
        margin-bottom: 40px;
        label {
          // width: 240px;
          font-size: 14px;

          color: #000000;
          line-height: 19px;
        }
        input {
          width: 170px;
          height: 26px;
          background: #f6f6f6;
          border-radius: 5px;
          border: 1px solid #d7d7d7;
          margin-right: 10px;
          padding-left: 10px;
        }
        img {
          width: 20px;
          height: 20px;
        }
        .tishi {
          color: red;
          font-size: 12px;
        }
      }
      .buttonBox {
        text-align: center;
        button {
          width: 96px;
          height: 40px;
          background: $homePageSubjectColor;
          border-radius: 4px;
          color: #ffffff;
          font-size: 16px;
        }
      }
    }
  }
  // 确认认证方式
  .checkMethods {
    padding-bottom: 188px;
    .checkMethodsCon {
      width: 450px;
      margin: 0 auto;
      padding-top: 70px;
      h1 {
        margin-bottom: 10px;
        font-size: 18px;
        color: #000000;
        line-height: 18px;
      }
      h2 {
        font-size: 14px;
        color: #989898;
        line-height: 21px;
        margin-bottom: 25px;
      }
      .checkMethodsOpt {
        width: 480px;
        height: 69px;
        cursor: pointer;
        background: #ffffff;
        border: 1px solid #e9e9e9;
        border-radius: 6px;
        margin-bottom: 11px;
        display: flex;
        align-items: center;
        padding: 0 17px 0 21px;
        &:last-child {
          margin-bottom: 0;
        }
        img {
          margin-right: 8px;
        }
        span {
          font-size: 15px;

          color: #000000;
          line-height: 15px;
          margin-right: auto;
        }
      }
    }
  }
  // 修改密码
  .changePasswordBox {
    padding-bottom: 100px;
    .userInfo {
      width: 1050px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0 auto 23px;
      span {
        font-size: 18px;

        color: #000000;
        line-height: 24px;
      }
      button {
        font-size: 16px;

        color: $homePageSubjectColor;
        line-height: 18px;

        background-color: transparent;
        border: none;
        outline: none;
      }
    }
    padding-top: 70px;

    > div {
      width: 476.7px;
      margin: 0 auto;
    }
    // 身份验证
    .identityVerification {
      .name {
        font-size: 18px;
        font-weight: 400;
        line-height: 18px;
        margin-bottom: 42px;
      }
      .selinputBox {
        /deep/.el-form-item {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 29px;
          &:last-child {
            margin-bottom: 0;
          }
          .el-form-item__label {
            width: 100%;
            font-size: 15px;
            font-weight: 400;
            line-height: 15px;
            padding-bottom: 13px;
            &:before {
              display: none;
            }
          }

          .el-form-item__content {
            display: flex;
            width: 100%;
            justify-content: center;
            align-items: center;
            // 手机区号显示
            .countryBox {
              width: 75px;
              height: 72px;
              background: #f4f7fc;
              border: 1px solid #dde0e6;
              border-radius: 6px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 8px;
            }
            // 输入框
            .inputSend {
              flex: 1;
              position: relative;
              input {
                width: 100%;
                height: 72px;
                background: #ffffff;
                border: 1px solid #dde0e6;
                border-radius: 6px;
                padding: 0 18px;
                font-size: 15px;
                font-weight: 300;
              }
              .gou {
                display: none;
                position: absolute;
                font-weight: bold;
                right: 24px;
                top: 50%;
                transform: translate(0, -50%);
                color: #417db4;
                &.regCodegou {
                  right: 222px;
                }
              }
              &.succ {
                input {
                  background: #f4f7fc;
                  border: 1px solid #afbbd1;
                }
                .gou {
                  display: block;
                }
              }
            }
            // 获取验证码按钮
            .getNum {
              width: 191px;
              height: 71px;
              background: #202a93;
              border: 1px solid #202a93;
              border-radius: 6px;
              margin-left: 12px;
              font-size: 18px;
              color: #ffffff;
            }
            // 下一步按钮
            .nextBtn {
              margin-top: 10px;
              width: 100%;
              height: 70px;
              background: #6dbb9d;
              border: 1px solid #6dbb9d;
              border-radius: 6px;
              font-size: 18px;
              font-weight: 400;
              color: #ffffff;
            }
            // 报错文字
            .el-form-item__error {
              top: 100%;
            }
            // 密码框显示密码图标
            .el-input__clear {
              font-size: 20px;
              margin-right: 10px;
            }
          }
        }
      }
      .regInputBox {
        margin-bottom: 20px;
        &:last-child {
          mask-border: 40px;
        }
        .numBox {
          width: 246px;
        }
        .emailLabel {
          width: 245px;
        }
        .regCodeBox {
          input {
            margin-bottom: 3px;
          }
          span {
            display: block;
            font-size: 14px;

            color: #ff0000;
            line-height: 20px;
          }
        }
        > label {
          display: inline-block;
          width: 246px;
          height: 20px;
          font-size: 14px;

          color: #000000;
          line-height: 20px;
          text-align: right;
        }

        /deep/.el-input {
          width: 90px; //组件宽
          height: 26px; //组件高
          margin-right: 10px;
        }
        /deep/.el-input__inner {
          //定义组件内容区域
          font-size: 12px;

          background-color: #f6f6f6;
          color: #000000;
          line-height: 16px;
          border-radius: 6px;
          height: 100%;
          border: 1px solid rgba($color: $homePageSubjectColor, $alpha: 0.3);
        }
        /deep/.el-select__caret {
          //组件内容小图标居中
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .numbox {
          display: inline-block;
          span {
            font-size: 14px;

            color: #ff0000;
            line-height: 20px;
          }
        }
        input {
          width: 240px;
          height: 26px;
          background: #f6f6f6;
          border-radius: 5px;
          border: 1px solid #d7d7d7;
          margin-right: 10px;
          padding-left: 10px;
        }
        > span {
          font-size: 14px;

          color: #ff0000;
          line-height: 20px;
        }
        > button {
          padding: 0 12px;
          background: #1e2997;
          border-radius: 5px;
          font-size: 12px;

          color: #ffffff;
          height: 26px;
          &.sixSec {
            background: #1e2997;
            border-radius: 5px;
            opacity: 0.3;
          }
        }
      }
    }

    // 修改成功
    .ChangeSuccess {
      .ChangeSuccessOpt {
        .successImg {
          text-align: center;
          margin-bottom: 22px;
          margin-top: 65px;
          img {
            width: 48px;
            height: 48px;
          }
        }
        .messageBox {
          text-align: center;
          margin-bottom: 22px;
          span {
            font-size: 17px;
            line-height: 17px;
          }
        }
        .messaeSpan {
          width: 254px;
          text-align: center;
          margin: 0 auto 43px;
          span {
            font-size: 15px;
            font-weight: 400;
            color: #989898;
            line-height: 24px;
          }
        }
        .nextBtn {
          width: 475px;
          height: 69px;
          background: #202a93;
          border: 1px solid #202a93;
          border-radius: 6px;
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          line-height: 69px;
        }
      }
    }
  }
}
// 星号
.import {
  font-size: 14px;

  color: #ff0000;
  line-height: 20px;
}
</style>
